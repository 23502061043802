export const isEmptyObject = object => Object.entries(object).length === 0
export const hasOnlyNullValues = object =>
  Object.entries(object).every(
    e => !e[1] || (Array.isArray(e[1]) && !e[1].length)
  )
export const cleanNulls = object => {
  for (const key in object)
    if (!object[key] || object[key].length === 0) object[key] = null
}

export const cleanup = object => {
  if (hasOnlyNullValues(object)) return {}
  cleanNulls(object)
  return object
}
