import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import useQueryParams from "../../hooks/useQueryParams"

import KnockOut from "../layout/KnockOut"
import { AuthContext } from "../../context/AuthContext"
import { queryCache } from "react-query"

const AccessCodePane = ({ confirmation }) => {
  const { refetch } = useContext(AuthContext)
  const [error, setError] = useState()
  const [accessCode, setAccessCode] = useState()
  const [loading, setLoading] = useState(false)

  const query = useQueryParams()
  const mode = query.get("mode")

  const submitSMSCode = async () => {
    setLoading(true)
    try {
      await confirmation.confirm(accessCode.trim())
      if (mode === "create") {
        setLoading(false)
        navigate("/inform?mode=auth-create&id=none")
      } else await refetch()

      const hasPod = queryCache.getQueryData("AuthContext - hasPod")
      setLoading(false)
      setError(null)
      if (hasPod) navigate(`/inform?mode=manage&id=${hasPod.id}`)
      else navigate("/inform?mode=auth-create&id=none")
    } catch (error) {
      console.error(error.message)
      setLoading(false)
      setError("Invalid access code, please try again")
    }
  }

  return (
    <div className="[ flow ][ shadow-box ] [ sign-in ]" id="access-code-field">
      {loading && <KnockOut />}
      <h2>Enter access code</h2>
      <p id="askCode">
        Your access code was sent to phone-number. Didn&apos;t get it? Send it
        again or enter a different phone number.
      </p>
      <input
        className="text-input"
        id="access-code"
        onChange={({ target: { value } }) => setAccessCode(value)}
        placeholder="Access code"
        aria-labelledby="askCode"
        onKeyDown={e => {
          if (e.keyCode == 13) {
            submitSMSCode()
          }
        }}
      />
      {error && <p>{error}</p>}
      <button className="button" onClick={submitSMSCode}>
        Submit
      </button>
      <label id="captcha-label" />
    </div>
  )
}

export default AccessCodePane
