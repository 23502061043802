import { cleanup } from "./object"

export const onChange = setFilters => {
  return ({ target: { name, value } }) => {
    setFilters(prevFilters => {
      let currentFilters = { ...prevFilters, [name]: value }
      currentFilters = cleanup(currentFilters)
      return currentFilters
    })
  }
}

export const onCheck = setFilters => {
  return ({ target: { name, value, checked } }) => {
    if (checked) setFilters(checkFilter(name, value))
    else setFilters(unCheckFilter(name, value))
  }
}
function unCheckFilter(name, value) {
  return prevFilters => {
    let currentFilters = {
      ...prevFilters,
      [name]: prevFilters[name].filter(i => i !== value),
    }
    if (!currentFilters[name].length) currentFilters[name] = null
    currentFilters = cleanup(currentFilters)
    return currentFilters
  }
}

function checkFilter(name, value) {
  return prevFilters => ({
    ...prevFilters,
    [name]: prevFilters[name] ? [...prevFilters[name], value] : [value],
  })
}

export const preventNegativeInput = e => {
  if (e.key === "Backspace") return

  const characterNumber = Number(e.key)
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return
    } else if (characterNumber < 0) {
      e.preventDefault()
    }
  } else {
    e.preventDefault()
  }
}
