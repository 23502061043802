import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"

// Layout
import { EnterPhonePane, AccessCodePane } from "../components/authentication"
import { AuthContext } from "../context/AuthContext"
import withWindow from "../helpers/withWindow"

const SignIn = () => {
  const { user } = useContext(AuthContext)
  const [confirmation, setConfirmation] = useState()
  const [togglePanes, setTogglePanes] = useState(false)
  const next = confirmation => {
    setConfirmation(confirmation)
    setTogglePanes(true)
  }

  useEffect(() => {
    user && !confirmation ? navigate("/inform?mode=signed") : null
  }, [user, confirmation])

  return (
    <>
      <Helmet>
        <title>Sign in</title>
      </Helmet>
      {togglePanes ? (
        <AccessCodePane confirmation={confirmation} />
      ) : (
        <EnterPhonePane next={next} />
      )}
    </>
  )
}

export default withWindow(SignIn)
