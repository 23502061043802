import React, { useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import { preventNegativeInput } from "../../helpers/filters"
import useQueryParams from "../../hooks/useQueryParams"

const EnterPhonePane = ({ next }) => {
  const [phoneNumber, setPhoneNumber] = useState()
  const [recaptcha, setRecaptcha] = useState()
  const [error, setError] = useState()

  const query = useQueryParams()
  const mode = query.get("mode")

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier("captcha-field")
    verifier
      .render()
      .then(() => setRecaptcha(verifier))
      .catch(() => setError("There was a problem rendering recaptcha"))
  }, [])

  const startSMSValidation = () => {
    const phone = "+1" + phoneNumber
    firebase
      .auth()
      .signInWithPhoneNumber(phone, recaptcha)
      .then(next)
      .catch(error => {
        setError("Invalid phone number, please try again")
        console.error(error.message)
      })
  }

  return (
    <div className="[ flow ][ shadow-box ] [ sign-in ]">
      <h2>What&apos;s your phone number?</h2>
      <p id="askPhone">
        {mode === "create"
          ? `You'll need to create an account before you can create or access
        your pod.`
          : `You'll receive a text message at this number with a code to sign in with.`}
      </p>
      <input
        type="tel"
        name="phone"
        className="text-input"
        id="phoneNumber"
        onKeyDown={preventNegativeInput}
        onChange={({ target: { value } }) => setPhoneNumber(value)}
        placeholder="Phone number"
        aria-labelledby="askPhone"
      />
      <div id="captcha-field" />
      {error && <p>{error}</p>}
      <button className="button" onClick={startSMSValidation}>
        Request access code
      </button>
    </div>
  )
}

export default EnterPhonePane
